

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
        IonInput, IonSelect, IonSelectOption, IonTextarea,
        IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton,
        loadingController,
        isPlatform,
        onIonViewDidEnter, } from '@ionic/vue';

import { computed, reactive, ref } from 'vue';

// icons
import { location, close } from 'ionicons/icons';

// services
import OrderService from '@/services/OrderService';
import CartService from '@/services/CartService';

import { loadStripe } from '@stripe/stripe-js';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';
import { utils } from '@/composables/utils';
import { Plugins } from '@capacitor/core';

export default {
  name: 'CheckoutPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
                IonInput, IonSelect, IonSelectOption, IonTextarea, 
                IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton, },
  setup() {
    const store = useStore();
    const { presentAlert, } = utils();

    const { Browser } = Plugins;

    const pickupPoints = computed(() => store.state.pickupPoints);
    const user = computed(() => store.state.user);
    const loading = ref(true);
    const cartItems = ref<any>(null);
    const needDelivery = ref(true); // if all cart items are services then no need delivery
    const order = reactive({
      pickupPoint: "",
      deliveryMethod: "送貨上門",
      deliveryDistrict: "",
      deliveryAddress: "",
      paymentMethod: "",
      customerName: user.value.firstName || "",
      contactPhone: user.value.phone || "",
      customerEmail: user.value.email || "",
      totalPrice: 0,
      stripeToken: null,
      remark: "",

      // Payment Asia integration fields
      paMerchantReference: "",
      paCustomerIp: "",
      paCurrency: "HKD",
      paReturnUrl: "https://asia-east2-a-gallery-tvp.cloudfunctions.net/order-paHandlePOSTResponse",
      paSubject: user.value.id,
      paAmount: "",
      paSign: "",
    });
    const availableDistricts = ref([
      "中西區",
      "東區",
      "南區",
      "灣仔",
      "深水埗",
      "九龍城",
      "觀塘",
      "黃大仙",
      "油尖旺區",
      "荃灣",
      "葵青區",
      "北區",
      "西貢",
      "沙田",
      "大埔",
      "屯門",
      "元朗",
      "天水圍",
      "離島",
    ]);
    const paymentProofFile = ref<any>(null);
    const paymentProofDataURL = ref("");

    const stripe = ref<any>(null);
    const card = ref<any>(null);
    const inviInput = ref<any>(null); // FOR FIXING IOS KEYBOARD PROBLEM

    loadStripe(config.Stripe.publishableKey).then((res: any) => {
      stripe.value = res;
      const elements = res.elements();

      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      // Create an instance of the card Element.
      card.value = elements.create('card', { hidePostalCode: true, style });

      // Add an instance of the card Element into the `card-element` <div>.
      card.value.mount('#card-element');

      // Handle real-time validation errors from the card Element.
      card.value.on('change', (event: any) => {
        const displayError: any = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      if (isPlatform('ios')) {
        card.value.on('blur', (e: any) => {
          inviInput.value.focus();
          setTimeout(() => {
            inviInput.value.blur();
          });
        })
      }
    })

    // methods
    const { t } = useI18n();
    const router = useRouter();
    const passedCartItems: any = history.state.passedCartItems; // route paramters (mainly ID)
    if (passedCartItems) { // from cart page
      loading.value = false;
      cartItems.value = JSON.parse(passedCartItems);
      needDelivery.value = cartItems.value.some((item: any) => item.productType == '' || item.productType == 'default');
    } else {
      CartService.getUserCartItems().then(res => {
        loading.value = false;
        cartItems.value = res;
        needDelivery.value = cartItems.value.some((item: any) => item.productType == '' || item.productType == 'default');
      });
    }
    const getCartTotal = () => (parseFloat((cartItems.value.reduce((a: any, b: any) => +a + (b.unitPrice * b.quantity), 0)).toFixed(2)));
    const submitOrder = async (paypalPaymentDetails = null) => {
      const loading = await loadingController.create({});
      await loading.present();
      order.totalPrice = getCartTotal();
      order["paypalPaymentDetails"] = paypalPaymentDetails;

      if (order.paymentMethod == 'Alipay') {
        // Redirect to Payment Asia
        order.paAmount = order.totalPrice.toFixed(2);
        const res = await OrderService.paGetHashedSignature(order);
        const { merchantReference, sign, customerIp } = res;
        order.paMerchantReference = merchantReference;
        order.paSign = sign;
        order.paCustomerIp = customerIp;

        // Add order to DB
        OrderService.createNewOrder(order, cartItems.value, paymentProofFile.value);

        setTimeout(() => {
          const paFormEl: any = document.querySelector('#pa-form');
          paFormEl.submit();

          setTimeout(() => {
            loadingController.dismiss();
            router.replace({ name: 'ThankYouPage' }); // will be triggered in mobile app
          }, 15000);
        }, 2500);
      }
      else {
        // Send Server Request & Redirect to Thank You Page
        if (order.paymentMethod == 'card') {
          const result = await stripe.value.createToken(card.value);
          if (result.error) {
            // Inform the user if there was an error.
            const errorElement: any = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
            loading.dismiss();
            return false;
          } else {
            order.stripeToken = result.token.id; // pass the token to server for creating a payment
          }
        }
        const res = await OrderService.createNewOrder(order, cartItems.value, paymentProofFile.value);
        router.replace({ name: 'ThankYouPage' });
        loading.dismiss();
      }
    }
    const onPaymentProofChange = (e: any) => {
      if (e.target.files && e.target.files[0]) {
        paymentProofFile.value = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          paymentProofDataURL.value = e.target.result;
        }
        reader.readAsDataURL(paymentProofFile.value); // convert to base64 string and preview it
        e.srcElement.value = null;
      }
    }
    const clearUploadedPaymentProof = () => {
      paymentProofDataURL.value = "";
      paymentProofFile.value = null;
    }
    const onPickupPointChanged = (e: any) => {
      order.deliveryAddress = pickupPoints.value.find((p: any) => p.id == e.target.value).address;
    }

    onIonViewDidEnter(() => {
      // PayPal button init
      const paypalConfig = {
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: getCartTotal(),
                currency_code: 'HKD',
              }
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
              locale: 'zh-HK',
            },
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then((details) => {
            console.log(details);
            // Handle different payment statuses
            if (details.status === 'COMPLETED' || details.status === 'PENDING') {
              // Store the payment details in your database
              submitOrder(details);
              
            } else if (details.status === 'CANCELLED') {
              // Handle the cancelled payment status
            } else {
              // Handle the failed payment status
              presentAlert('PayPal 付款失敗，請重試或選擇其他付款方式。');
            }
          });
        },
        onCancel: (data) => {
          // Handle the cancellation of the payment
        },
        onError: (err) => {
          console.log(err);
          // Handle any errors that occur during the checkout process
          presentAlert('PayPal 付款過程出現錯誤，請重試或選擇其他付款方式。');
        },
        style: {
          layout: 'horizontal',
          color: 'gold',
          shape: 'rect',
          label: 'paypal',
          tagline: false,
        },
      };
      const paypalButtons = window["paypal"].Buttons(paypalConfig);
      paypalButtons.render('#paypal-button-container');
    })
    
    return {
      t,

      // icons
      location, close,
      
      // variables
      loading, cartItems, needDelivery, availableDistricts, order, submitOrder,

      paymentMethods: config.paymentMethods, paymentProofFile, paymentProofDataURL,

      pickupPoints, inviInput,

      // methods
      getCartTotal, onPaymentProofChange, clearUploadedPaymentProof, onPickupPointChanged,
    }
  },
}
