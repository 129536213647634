import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/storage';
import { getPlatforms } from '@ionic/vue';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload

const uploadPaymentProof = async (file: any, customerName: string) => {
  // compress the image
  file = await new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = file.name.split('.').pop();
  const fileName = `${moment().format('YYYYMMDDHHmmss')}-${customerName.substring(0, 10)}.${extension}`;
  const snapshot = await firebase.storage().ref(`orderPaymentProof-${fileName}`).put(file);
  return await snapshot.ref.getDownloadURL();
}

export default {
  // Payment Asia
  async paGetHashedSignature(order: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-paGetHashedSignature')(order)).data;
  },

  // READ
  async getUserOrders() {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getUserOrders')({ })).data;
  },
  async getOrderById(id: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getOrderById')({ id })).data;
  },
  async getOrderItems(orderId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getOrderItems')({ orderId })).data;
  },

  // CREATE
  async createNewOrder(order: any, cartItems: any, paymentProofFile: any = null) {
    if (paymentProofFile) {
      // mainly for bank transfer orders
      order.paymentProof = await uploadPaymentProof(paymentProofFile, order.customerName);
    }
    order.sourceName = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('order-createNewOrder')({ order, cartItems });
    return res;
  }
}